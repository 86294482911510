import { Fragment } from "react"
import { Route, BrowserRouter as Router, Routes } from "react-router-dom"
import Principal from "../componentes/Principal"
import Construccion from "../componentes/Construccion"
import Page404 from "../componentes/Page404"
import Blogp from "../componentes/Blogp"
import Pfp from "../componentes/Pfp"
import Imf from "../componentes/Imf"
import Ef from "../componentes/Ef"
import Efc from "../componentes/Efc"
import Tf from "../componentes/Tf"
import Egpf from "../componentes/Egpf"



const Rutas = () => {


    return (
        <Router>
            <Fragment>
                <Routes>
                    <Route path="/" element={<Principal />} />
                    <Route path="/blog" element={<Blogp />} />
                    <Route path="/planificacion-financiera-personal" element={<Pfp />} />
                    <Route path="/inversiones-mercados-financieros" element={<Imf />} />
                    <Route path="/educacion-financiera" element={<Ef />} />
                    <Route path="/emprendimiento-finanzas-corporativas" element={<Efc />} />
                    <Route path="/tecnologia-finanzas" element={<Tf />} />
                    <Route path="/economia-global-politica-financiera" element={<Egpf />} />
                    <Route path="*" element={<Page404 />} />
                </Routes>
            </Fragment>

        </Router>
    )



}

export default Rutas