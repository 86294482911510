
import WhatsAppIcon from '@mui/icons-material/WhatsApp';  
import EmailIcon from '@mui/icons-material/EmailOutlined';


const Footer = () => {

    return (
        <>
            <div className="container-fluid bg-pcolor py-5" id="contacto">
                <div className="container">
                    <div className="row d-flex justify-content-center ">
                        <div className="col-md-4">
                            <img src="/img/sgwhite.png" alt="" className="img-fluid" width={250} />
                            <p className="text-white font-page" style={{ 'textAlign': 'justify' }}>Descubre nuevas oportunidades de inversión con nosotros. Información financiera y asesoramiento para potenciar tu futuro financiero.</p>
                        </div>
                        <div className="col-md-4"></div>
                        <div className={`col-md-4 text-white p-4 `}>
                            <div className="row">
                                <div className="col-md-2">
                                    <WhatsAppIcon style={{ 'fontSize': '50' }} />
                                </div>
                                <div className="col-md-10">
                                    <span className="fs-info-footer">WhatsApp</span>
                                    <p className="fw-bold fs-info2-footer">+52 686 243 8051 / +52 686 357 1355</p>
                                </div>
                            </div>
                            <div className="row pt-3 ">
                                <div className="col-md-2">
                                    <EmailIcon style={{ 'fontSize': '50' }} />
                                </div>
                                <div className="col-md-10">
                                    <span className="fs-info-footer">Escríbenos</span>
                                    <p className="fw-bold fs-info2-footer">contacto@sgcapital.com.mx</p>
                                </div>
                            </div>

                        </div>
                        <hr className="mt-2" style={{ 'color': 'white' }} />
                    </div>
                </div>

            </div>
        
        </>


    )

}

export default Footer