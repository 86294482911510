
import { Link } from "react-router-dom";
import Footer from "./Footer";
import NavBlog from "./NavBlog";


const Blogp = () => {

   

    return (
        <div className="animation-p">

            <NavBlog/>


            <div className="container py-5 mt-5">
                <div className="row py-5">

                    <div className="col-md-4 mb-3">
                        <div className="card-transparent h-100">
                            <div className="card-body bg-scolor h-100 rounded p-0" style={{'border' : 'solid 1px #00F0F1'}}>
                                <Link to={'/planificacion-financiera-personal'}>
                                    <div className="z-image-container">
                                        <img src="/img/post1blog.png" alt="" className="img-fluid pointer zoom-img-blog " />
                                    </div>
                                    
                                </Link>
                                <div className="bg-scolor txt-grey p-4 font-page" >
                                    <Link to={'/planificacion-financiera-personal'} className="txt-blog">
                                        <h5 className="txt-scolor">Planificación Financiera Personal</h5>
                                    </Link>
                                    <br />
                                    <p>Crear un presupuesto efectivo es fundamental para gestionar tus finanzas de manera responsable y alcanzar tus metas financieras [...]</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div className="card-transparent h-100">
                            <div className="card-body bg-scolor h-100 rounded p-0" style={{'border' : 'solid 1px #00F0F1'}}>
                                <Link to={'/inversiones-mercados-financieros'}>
                                    <div className="z-image-container">
                                        <img src="/img/post2blog.png" alt="" className="img-fluid pointer zoom-img-blog " />
                                    </div>
                                    
                                </Link>
                                <div className="txt-grey p-4 font-page">
                                    <Link to={'/inversiones-mercados-financieros'} className="txt-blog">
                                        <h5 className="txt-scolor">Inversiones y Mercados Financieros</h5>
                                    </Link>
                                    <br />
                                    <p>Estos conceptos te proporcionan una base sólida para entender las diferentes opciones de inversión, estrategias para invertir a corto y largo plazo [...]</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div className="card-transparent h-100">
                            <div className="card-body bg-scolor h-100 rounded p-0" style={{'border' : 'solid 1px #00F0F1'}}>
                                <Link to={'/educacion-financiera'}>
                                    <div className="z-image-container">
                                        <img src="/img/post3blog.png" alt="" className="img-fluid pointer zoom-img-blog" />
                                    </div>
                                    
                                </Link>
                                <div className="txt-grey p-4 font-page ">
                                    <Link to={'/educacion-financiera'} className="txt-blog">
                                        <h5 className="txt-scolor">Educación Financiera</h5>
                                    </Link>
                                    <br />
                                    <p >Estos conceptos son fundamentales para una sólida educación financiera y te permitirán tomar decisiones informadas y efectivas para manejar [...]</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div className="card-transparent h-100">
                            <div className="card-body bg-scolor h-100 rounded p-0" style={{'border' : 'solid 1px #00F0F1'}}>
                                <Link to={'/emprendimiento-finanzas-corporativas'}>
                                    <div className="z-image-container">
                                        <img src="/img/post4blog.png" alt="" className="img-fluid pointer zoom-img-blog" />
                                    </div>
                                    
                                </Link>
                                <div className="txt-grey p-4 font-page ">
                                    <Link to={'/emprendimiento-finanzas-corporativas'} className="txt-blog">
                                        <h5 className="txt-scolor">Emprendimiento y Finanzas Corporativas</h5>
                                    </Link>
                                    <br />
                                    <p >El entendimiento profundo de estas áreas es esencial para emprendedores y empresarios que buscan establece y hacer crecer sus negocios [...]</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div className="card-transparent h-100">
                            <div className="card-body bg-scolor h-100 rounded p-0" style={{'border' : 'solid 1px #00F0F1'}}>
                                <Link to={'/tecnologia-finanzas'}>
                                    <div className="z-image-container">
                                        <img src="/img/post5blog.png" alt="" className="img-fluid pointer zoom-img-blog" />
                                    </div>
                                    
                                </Link>
                                <div className="txt-grey p-4 font-page ">
                                    <Link to={'/tecnologia-finanzas'} className="txt-blog">
                                        <h5 className="txt-scolor">Tecnología y Finanzas</h5>
                                    </Link>
                                    <br />
                                    <p >La integración de tecnología en el sector financiero está transformando la manera en que las personas manejan sus finanzas y acceden a servicios financieros [...]</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div className="card-transparent h-100">
                            <div className="card-body bg-scolor h-100 rounded p-0" style={{'border' : 'solid 1px #00F0F1'}}>
                                <Link to={'/economia-global-politica-financiera'}>
                                    <div className="z-image-container">
                                        <img src="/img/post6blog.png" alt="" className="img-fluid pointer zoom-img-blog" />
                                    </div>
                                    
                                </Link>
                                <div className="txt-grey p-4 font-page ">
                                    <Link to={'/economia-global-politica-financiera'} className="txt-blog">
                                        <h5 className="txt-scolor">Economía Global y Política Financiera</h5>
                                    </Link>
                                    <br />
                                    <p >La comprensión y aplicación de estas estrategias y principios son cruciales para individuos, empresas y gobiernos que desean mitigar los riesgos  [...]</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <Footer/>

        
        </div>
    )

}

export default Blogp