import { Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home';

const Page404 = () => {

    return(
        <div className='container-fluid animation-layout bg-404'>
            <div className='row py-5'>
                <div className='col-md-12 text-center py-5'>
                    <img src='/img/img404.png' width={'500px'} alt='imagen404.png' /> <br/>
                    <h3 className='txt-grey'>No se encontró ruta!!</h3>
                    <Button variant='contained' className='btn404' >
                        <Link to={'/'} style={{'textDecoration' : 'none', 'color' : '#fff'}}>
                            <HomeIcon/>
                        </Link>
                    </Button>
                </div>
            </div>
        </div>
    )

}

export default Page404