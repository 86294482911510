import Footer from "./Footer"
import Imgfblog from "./Imgfblog"
import NavBlog from "./NavBlog"



const Imf = () => {

    return (

        <div className="animation-p">

            <NavBlog/>


            <div className="container py-5 mt-5">
                <div className="row py-5 d-flex justify-content-center">
                    <h1 className="txt-scolor text-center fw-bold">Inversiones y Mercados Financieros</h1>
                    <div className="col-md-4 mb-3">
                        <img src="/img/post2blog.png" alt="" className="img-fluid" />
                    </div>
                    <div className="col-md-10">
                        <p className="txt-grey mb-5">Estos conceptos te proporcionan una base sólida para entender las diferentes opciones de inversión, estrategias para invertir a corto y largo plazo, cómo analizar tendencias del mercado y cómo evaluar y mitigar los riesgos asociados con las inversiones financieras. Es importante adaptar estas estrategias según tu situación financiera personal y objetivos de inversión específicos.</p>
                    
                        <h5 className="txt-scolor fw-bold mb-3">Introducción a los diferentes tipos de inversiones</h5>
                    
                        <ol className="ps-3 txt-scolor mb-5" >
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Acciones (Stocks):</span>
                                <ul>
                                    <li className="txt-grey">Representan la propiedad parcial de una empresa.</li>
                                    <li className="txt-grey">Los inversores compran acciones en anticipación de que el valor de estas aumentará con el tiempo, permitiéndoles beneficiarse de la apreciación del precio de las acciones y de los dividendos (en caso de que se paguen).</li>
                                </ul>                             
                            </li>
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Bonos (Bonds):</span>
                                <ul>
                                    <li className="txt-grey">Son deuda emitida por gobiernos, municipios o corporaciones.</li>
                                    <li className="txt-grey">Los inversores compran bonos y reciben pagos de intereses periódicos (cupones) hasta el vencimiento del bono, momento en el cual reciben el valor nominal del bono.</li>
                                    <li className="txt-grey">Considerados menos arriesgados que las acciones, pero generalmente ofrecen rendimientos más bajos.</li>
                                </ul>                             
                            </li>
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Fondos Indexados:</span>
                                <ul>
                                    <li className="txt-grey">Fondos de inversión que replican un índice bursátil específico (como el S&P 500).</li>
                                    <li className="txt-grey">Ofrecen diversificación automática al invertir en múltiples acciones según la composición del índice.</li>
                                    <li className="txt-grey">Menores costos de gestión en comparación con fondos activos, ya que no requieren una gestión constante.</li>
                                </ul>                             
                            </li>
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Fondos Mutuos (Mutual Funds):</span>
                                <ul>
                                    <li className="txt-grey">Pools de dinero de múltiples inversores administrados profesionalmente.</li>
                                    <li className="txt-grey">Invierten en una variedad de activos, como acciones, bonos y otros valores.</li>
                                    <li className="txt-grey">Proporcionan diversificación instantánea y son adecuados para inversores que prefieren no administrar activamente sus inversiones.</li>
                                </ul>                             
                            </li>
                            
                        </ol>

                        <h5 className="txt-scolor fw-bold mb-3">Estrategias de inversión a corto y largo plazo</h5>
                        <ol className="ps-3 txt-scolor mb-5" >
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Inversión a Corto Plazo:</span>
                                <ul>
                                    <li className="txt-grey">Se refiere a la compra y venta de activos con la intención de beneficiarse de movimientos de precios a corto plazo.</li>
                                    <li className="txt-grey">Estrategias comunes incluyen el day trading y el swing trading.</li>
                                    <li className="txt-grey">Implica un mayor nivel de riesgo y requiere un análisis técnico detallado y una rápida toma de decisiones.</li>
                                </ul>                             
                            </li>
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Inversión a Largo Plazo:</span>
                                <ul>
                                    <li className="txt-grey">Implica la compra y retención de activos durante períodos prolongados, generalmente más de un año.</li>
                                    <li className="txt-grey">Estrategias como invertir en acciones de empresas sólidas con buenos fundamentos o en fondos indexados.</li>
                                    <li className="txt-grey">Menor estrés y menor frecuencia de transacciones comparado con la inversión a corto plazo.</li>
                                </ul>                             
                            </li>
                            
                        </ol>

                        <h5 className="txt-scolor fw-bold mb-3">Análisis de tendencias del mercado y pronósticos</h5>
                        <ol className="ps-3 txt-scolor mb-5" >
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Análisis Fundamental:</span>
                                <ul>
                                    <li className="txt-grey">Evalúa el valor intrínseco de un activo basándose en factores económicos, financieros y cualitativos.</li>
                                    <li className="txt-grey">Examina ingresos, ganancias, deuda, gestión y perspectivas de crecimiento de una empresa para determinar su valoración.</li>
                                </ul>                             
                            </li>
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Análisis Técnico:</span>
                                <ul>
                                    <li className="txt-grey">Examina patrones pasados de precios y volumen de negociación para predecir futuros movimientos de precios.</li>
                                    <li className="txt-grey">Utiliza herramientas como gráficos y indicadores técnicos (como medias móviles y RSI) para identificar tendencias y puntos de entrada y salida.</li>
                                </ul>                             
                            </li>
                            
                        </ol>

                        <h5 className="txt-scolor fw-bold mb-3">Evaluación de riesgos y cómo mitigarlos</h5>
                        <ol className="ps-3 txt-scolor mb-5" >
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Diversificación:</span>
                                <ul>
                                    <li className="txt-grey">Distribuir inversiones en diferentes clases de activos y geografías para reducir el riesgo de pérdida en un solo activo.</li>
                                </ul>                             
                            </li>
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Asignación de activos:</span>
                                <ul>
                                    <li className="txt-grey">Determinar la mezcla adecuada de acciones, bonos y otros activos en función del perfil de riesgo y los objetivos de inversión del inversor.</li>
                                </ul>                             
                            </li>
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Gestión del riesgo financiero:</span>
                                <ul>
                                    <li className="txt-grey">Establecer límites de pérdida y utilizar órdenes de stop-loss para limitar las pérdidas en caso de movimientos adversos del mercado.</li>
                                </ul>                             
                            </li>
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Comprensión del riesgo específico del activo:</span>
                                <ul>
                                    <li className="txt-grey">Evaluar los riesgos específicos asociados con cada tipo de inversión (por ejemplo, riesgo de crédito en bonos corporativos, riesgo sectorial en acciones).</li>
                                </ul>                             
                            </li>
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Monitoreo y ajuste:</span>
                                <ul>
                                    <li className="txt-grey">Revisar regularmente la cartera de inversiones y ajustar la asignación de activos según cambios en las condiciones del mercado y en el perfil de riesgo del inversor.</li>
                                </ul>                             
                            </li>
                            
                        </ol>



                    </div>

                    <Imgfblog />

                </div>
            </div>

            <Footer/>

        
        </div>

    )

}


export default Imf