import { Box, Button, Fade, Modal, useMediaQuery } from "@mui/material"
import { useEffect, useState } from "react";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';  
import EmailIcon from '@mui/icons-material/EmailOutlined';
import Backdrop from '@mui/material/Backdrop';
import { useTheme } from '@mui/material/styles';
import { Link } from "react-router-dom";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    //width: '40%',
    bgcolor: '#023047',
    boxShadow: 24,
    p: 4,
    fontFamily: 'Montserrat',
    color: 'white',
    borderRadius: '15px',
  };

const Principal = () => {

    const theme = useTheme(); 
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); 

    
    
    const navigation =(text)=>{
        //eventHandler(text);
        const navbarToggler = document.querySelector(".navbar-toggler");
        if (navbarToggler && navbarToggler.getAttribute("aria-expanded") === "true") {
          navbarToggler.click();
        }
    
        let nav = document.querySelector('.navbar').offsetHeight;
        const componente = document.getElementById(text).offsetTop;

        if(window.innerWidth <= 768) nav = 54
    
        window.scrollTo({
          top: componente - nav,
          behavior: 'smooth',
        });
        
    
      }

    const [hasScrolled, setHasScrolled] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
      
    useEffect(() => {

        const handleScroll = () => {
          // Verifica la posición del scroll
          const isScrolled = window.scrollY > 0;
          setHasScrolled(isScrolled);
        };
    
        // Agrega el event listener para el scroll
        window.addEventListener('scroll', handleScroll);
    
        // Limpia el event listener cuando el componente se desmonta
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [claseContacto, setClaseContacto] = useState(false)

    const activarCuadro = () => {
        setClaseContacto(true)
        navigation('contacto')

        setTimeout(() => {
            setClaseContacto(false)
        }, 5000)

    }

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };


    return (
        <div className="animation-p">

            <nav className={`navbar navbar-expand-lg navbar-light fixed-top bg-pcolor ${hasScrolled ? 'shadow' : ''}`}>
                <div className="container-fluid" style={{ 'zIndex': '99' }} id="navbar">
                    <div className="container">
                        <div className="row p-2">
                            <div className="col-md-6 d-flex align-items-center justify-content-center">
                                <img src="/img/logonav.png" alt="" className="logo" width={'200px'} />
                            </div>
                            <div className="col-md-6 d-flex align-items-center justify-content-center" id="menu">
                                <span className="fw-bold pmenu px-2 pointer text-light" onClick={() => navigation('inicio')}>Inicio</span> <div className="vertical-line"></div>
                                <span className="fw-bold pmenu px-2 pointer  text-light" onClick={() => navigation('servicios')}>Servicios</span> <div className="vertical-line"></div>
                                <span className="fw-bold pmenu px-2 pointer text-light" onClick={() => navigation('planes')}>Planes</span> <div className="vertical-line"></div>
                                <span className="fw-bold pmenu px-2 pointer text-light" onClick={activarCuadro}>Contacto</span>
                                <Link to={'/blog'} className="fw-bold txt-blog px-2 pointer text-light">
                                    Blog
                                </Link>
                                {/*<Link to={'/faqs'} className="fw-bold txt-blog px-2 pointer text-light">
                                    FAQ's
                                </Link>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

            
            {/* hacer bien que muestre el video completo en la pantalla sin que se cambie */}


            <div className="container-fluid px-0 pb-0 bg-pcolor pmovil" id="inicio" >
                <div className="row p-0 m-0">
                    <div className="col-md-12 p-0">
                        <video autoPlay muted id="myVideo" className="maxw">
                            <source src="/img/ppsg.mp4" type="video/mp4"/>
                        </video>
                    </div>
                </div>
            </div>

            <div className="container-fluid bg-pcolor py-5 " id="servicios">
                <div className="container py-5">
                    <div className="row d-flex justify-content-center align-items-center py-4">
                        <div className="col-md-6 text-light font-page">
                            <p>SERVICIOS</p>
                            <h1>Servicios Financieros</h1>
                            <p style={{fontSize: '14px'}} className="txt-grey">¡Haz crecer tus ahorros! Planifica, ahorra y disfruta de un futuro financiero sólido.</p>
                            {/*Boton de planes*/}
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6 pt-3 mb-3">
                                    <div className="card-transparent bg-scolor rounded shadow">
                                        <div className="card-body p-4 font-page">
                                            <img src="/img/seguridad.png" alt="" width={'50px'}/>
                                            <h3 className="text-light pt-5">Seguridad</h3>
                                            <p style={{fontSize: '14px'}} className="txt-grey">Asegura que tu dinero esté protegido contra fraudes y robos.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card-transparent bg-scolor rounded shadow">
                                        <div className="card-body p-4 font-page">
                                            <img src="/img/educacion.png" alt="" width={'50px'}/>
                                            <h3 className="text-light pt-5">Educación</h3>
                                            <p style={{fontSize: '14px'}} className="txt-grey">Recursos y herramientas para mejorar tu conocimiento financiero.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 pt-3 mb-3">
                                    <div className="card-transparent bg-scolor rounded shadow">
                                        <div className="card-body p-4 font-page">
                                            <img src="/img/transparencia.png" alt="" width={'50px'}/>
                                            <h3 className="text-light pt-5">Transparencia</h3>
                                            <p style={{fontSize: '14px'}} className="txt-grey">Una comunicación clara y abierta sobre términos, condiciones y rendimientos asociados con los planes.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card-transparent bg-scolor rounded shadow">
                                        <div className="card-body p-4 font-page">
                                            <img src="/img/accesibilidad.png" alt="" width={'50px'}/>
                                            <h3 className="text-light pt-5">Accesibilidad</h3>
                                            <p style={{fontSize: '14px'}} className="txt-grey">Garantiza que puedas gestionar tus finanzas de manera fácil y conveniente.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/**SEGUIR CON DISENIO DE PLANES */}

            <div className="container-fluid p-0" id="planes">
                <div className="row p-0 m-0">
                    <div className="col-md-12 p-0">
                        <img src="/img/planes.png" alt="" className="maxw" />
                    </div>
                </div>
            </div>

            <div className="container-fluid bg-pcolor pb-5  d-flex justify-content-center" id="planes">
                <div className="row p-0 m-0 ">
                    <div className="col-md-12 p-0 font-page">
                        <h3 className="text-light pt-5">¿Te interesa alguno de nuestros planes?</h3>
                        <div className=" d-flex justify-content-center">
                            <button className="betdi" onClick={activarCuadro}>Contactanos</button>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="container-fluid p-0">
                <div className="row p-0 m-0">
                    <div className="col-md-12 p-0">
                    <img src="/img/futuro.png" alt="" className="maxw"/>
                    </div>
                </div>
            </div>
            
            <div className="py-3 bg-pcolor"></div>

            <div className='container-fluid bg-pcolor '>
                <div className='row'>
                    <div className='col-md-12 m-0 p-0'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53771.45561486374!2d-115.49149052497935!3d32.61375182522858!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80d7700ca877ddd3%3A0xd40033a0e5cdf59a!2sMexicali%2C%20Baja%20California!5e0!3m2!1sen!2smx!4v1694838748041!5m2!1sen!2smx" width="100%" height="450" style={{'border':'0'}} loading="lazy"></iframe>
                    </div>
                </div>
            </div>

            <div className="container-fluid bg-pcolor py-5" id="contacto">
                <div className="container">
                <div className="row d-flex justify-content-center ">
                    <div className="col-md-4">
                        <img src="/img/sgwhite.png" alt="" className="img-fluid" width={250}/>
                        <p className="text-white font-page" style={{'textAlign':'justify'}}>Descubre nuevas oportunidades de inversión con nosotros. Información financiera y asesoramiento para potenciar tu futuro financiero.</p>
                    </div>
                    <div className="col-md-4"></div>
                    <div className={`col-md-4 text-white p-4 ${claseContacto ? 'cuadrado-border' : ''}`}>
                        <div className="row">
                            <div className="col-md-2">
                                <WhatsAppIcon style={{'fontSize' : '50'}}/>           
                            </div>
                            <div className="col-md-10">
                                <span className="fs-info-footer">WhatsApp</span>
                                <p className="fw-bold fs-info2-footer">+52 686 243 8051 / +52 686 357 1355</p>
                            </div>
                        </div>
                        <div className="row pt-3 ">
                            <div className="col-md-2">
                                <EmailIcon style={{'fontSize' : '50'}}/>
                            </div>
                            <div className="col-md-10">
                                <span className="fs-info-footer">Escríbenos</span>
                                <p className="fw-bold fs-info2-footer">contacto@sgcapital.com.mx</p>
                            </div>
                        </div>
                        
                    </div>
                    <hr className="mt-2" style={{'color': 'white'}}/>
                </div>
                </div>
                
            </div>


            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
                
            >
                <Fade in={open}>
                    <Box sx={style} style={{width: isSmallScreen ? '90%' : '40%'}} >
                        <h3 className="text-center pb-3">¡Contáctanos con gusto, te atenderemos!</h3>
                            <div className="row">
                                <div className={`col-md-2  ${isSmallScreen ? '' : 'text-end'}`}>
                                    <WhatsAppIcon style={{ fontSize: '50' }}/>           
                                </div>
                                <div className="col-md-10">
                                    <span className="fs-info-footer">WhatsApp</span>
                                    <p className="fw-bold fs-info2-footer">+52 686 243 8051 / +52 686 357 1355</p>
                                </div>
                            </div>
                            <div className="row pt-3 ">
                                <div className={`col-md-2  ${isSmallScreen ? '' : 'text-end'}`}>
                                    <EmailIcon style={{ fontSize: '50' }}/>
                                </div>
                                <div className="col-md-10">
                                    <span className="fs-info-footer">Escríbenos</span>
                                    <p className="fw-bold fs-info2-footer">info@sgcapital.com.mx</p>
                                </div>
                            </div>
                    </Box>
                </Fade>
            </Modal>

        </div>
    )

}

export default Principal