import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import NavBlog from "./NavBlog";
import Imgfblog from "./Imgfblog";


const Pfp = () => {

    return (
        <div className="animation-p">

            <NavBlog/>


            <div className="container py-5 mt-5">
                <div className="row py-5 d-flex justify-content-center">
                    <h1 className="txt-scolor text-center fw-bold">Planificación Financiera Personal</h1>
                    <div className="col-md-4 mb-3">
                        <img src="/img/post1blog.png" alt="" className="img-fluid" />
                    </div>
                    <div className="col-md-10">
                        <p className="txt-grey mb-5">Crear un presupuesto, ahorrar dinero, gestionar deudas y establecer un fondo de emergencia son componentes clave de una planificación financiera personal efectiva. Implementar estos principios te ayudará a alcanzar tus metas financieras y a mantener una estabilidad económica a largo plazo.</p>
                    
                        <h5 className="txt-scolor fw-bold">Estrategias para crear un presupuesto efectivo</h5>
                        <p className="txt-grey mb-3">Crear un presupuesto efectivo es fundamental para gestionar tus finanzas de manera responsable y alcanzar tus metas financieras. Aquí tienes algunas estrategias clave.</p>
                    
                        <ul className="ps-3 txt-scolor mb-5" >
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Calcula tus ingresos y gastos:</span>
                                &nbsp;<span className="txt-grey">Enumera todos tus ingresos mensuales y luego detalla tus gastos fijos (como alquiler, servicios públicos, transporte) y variables (como alimentos, entretenimiento).</span>
                            </li>
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Prioriza tus gastos:</span>
                                &nbsp;<span className="txt-grey">Identifica qué gastos son esenciales y cuáles son opcionales. Asegúrate de cubrir primero tus necesidades básicas antes de gastar en cosas no esenciales.</span>
                            </li>
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Establece metas financieras:</span>
                                &nbsp;<span className="txt-grey">Decide qué quieres lograr con tu presupuesto, ya sea pagar deudas, ahorrar para un objetivo específico o invertir para el futuro.</span>
                            </li>
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Utiliza herramientas de presupuesto:</span>
                                &nbsp;<span className="txt-grey">Aplicaciones y programas como Mint, YNAB (You Need A Budget) o una simple hoja de cálculo pueden ayudarte a llevar un seguimiento de tus ingresos y gastos de manera efectiva.</span>
                            </li>
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Revisa y ajusta regularmente:</span>
                                &nbsp;<span className="txt-grey">Revisa tu presupuesto periódicamente para asegurarte de que siga siendo realista y ajusta según sea necesario en función de cambios en tus ingresos o gastos.</span>
                            </li>
                        </ul>
                    
                        <h5 className="txt-scolor fw-bold">Consejos para ahorrar dinero</h5>
                        <p className="txt-grey mb-3">Ahorrar dinero es crucial para construir seguridad financiera a largo plazo. Aquí tienes algunos consejos prácticos para ahorrar</p>
                    
                        <ul className="ps-3 txt-scolor mb-5">
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Establece un objetivo de ahorro:</span>
                                &nbsp;<span className="txt-grey">Decide cuánto deseas ahorrar mensualmente y establece un objetivo claro.</span>
                            </li>
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Automatiza tus ahorros:</span>
                                &nbsp;<span className="txt-grey">Configura transferencias automáticas desde tu cuenta corriente a una cuenta de ahorros separada para evitar la tentación de gastar ese dinero.</span>
                            </li>
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Reduce gastos innecesarios:</span>
                                &nbsp;<span className="txt-grey">Identifica áreas donde puedas recortar gastos, como comer fuera o suscripciones que no utilizas.</span>
                            </li>
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Compra de manera inteligente:</span>
                                &nbsp;<span className="txt-grey">Usa cupones, espera ventas o compra productos de segunda mano para reducir los costos.</span>
                            </li>
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Crea un fondo de emergencia:</span>
                                &nbsp;<span className="txt-grey">Tener un fondo de emergencia sólido te permite cubrir gastos inesperados sin tener que recurrir a deudas.</span>
                            </li>
                        </ul>

                        <h5 className="txt-scolor fw-bold">Cómo gestionar deudas de manera inteligente</h5>
                        <p className="txt-grey mb-3">Manejar las deudas de manera efectiva te ayuda a evitar problemas financieros y a mejorar tu situación crediticia. Aquí hay algunos consejos</p>
                    
                        <ul className="ps-3 txt-scolor mb-5">
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Prioriza tus deudas:</span>
                                &nbsp;<span className="txt-grey">Paga primero las deudas con tasas de interés más altas para minimizar el costo total.</span>
                            </li>
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Negocia tasas de interés:</span>
                                &nbsp;<span className="txt-grey">Si es posible, negocia con tus acreedores para reducir las tasas de interés, especialmente si tienes un buen historial de pago.</span>
                            </li>
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Consolida tus deudas:</span>
                                &nbsp;<span className="txt-grey">Considera consolidar deudas de alto interés en un préstamo con una tasa más baja o mediante una transferencia de saldo a una tarjeta de crédito con una tasa promocional baja.</span>
                            </li>
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Crea un plan de pago:</span>
                                &nbsp;<span className="txt-grey">Establece un plan claro para pagar tus deudas, ya sea utilizando el método de bola de nieve (pagando primero las deudas más pequeñas) o el método de avalancha (pagando primero las deudas con tasas de interés más altas).</span>
                            </li>
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Evita incurrir en más deudas:</span>
                                &nbsp;<span className="txt-grey">Una vez que comiences a pagar tus deudas, evita tomar nuevas deudas a menos que sea absolutamente necesario.</span>
                            </li>
                        </ul>

                        <h5 className="txt-scolor fw-bold">Importancia del fondo de emergencia y cómo empezar uno</h5>
                        <p className="txt-grey mb-3">Un fondo de emergencia te proporciona seguridad financiera al cubrir gastos inesperados, como reparaciones de automóviles o facturas médicas. Aquí tienes pasos para empezar uno</p>
                    
                        <ul className="ps-3 txt-scolor mb-5">
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Establece un objetivo</span>
                                &nbsp;<span className="txt-grey">Generalmente, se recomienda ahorrar al menos de 3 a 6 meses de gastos de subsistencia en tu fondo de emergencia.</span>
                            </li>
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Calcula tus gastos mensuales:</span>
                                &nbsp;<span className="txt-grey">Determina cuánto necesitas cubrir tus gastos esenciales durante varios meses sin ingresos.</span>
                            </li>
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Abre una cuenta separada:</span>
                                &nbsp;<span className="txt-grey">Crea una cuenta de ahorros separada o utiliza una cuenta de mercado monetario para tu fondo de emergencia, asegurándote de que sea accesible pero no demasiado fácil de usar para gastos cotidianos.</span>
                            </li>
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Automatiza tus ahorros:</span>
                                &nbsp;<span className="txt-grey">Configura transferencias automáticas de tus ingresos o cuenta corriente a tu fondo de emergencia para construirlo de manera constante.</span>
                            </li>
                            <li className="mb-2">
                                <span className="txt-scolor fw-bold">Revisa y ajusta según sea necesario:</span>
                                &nbsp;<span className="txt-grey">Revisa periódicamente tu fondo de emergencia para asegurarte de que siga siendo suficiente, especialmente después de utilizarlo para una emergencia.</span>
                            </li>
                        </ul>





                    </div>

                    <Imgfblog />

                </div>
            </div>

            <Footer/>

        
        </div>
    )

}

export default Pfp