


const Imgfblog = () => {

    return (

        <div className="col-md-8 ">
            <img src="/img/footerblog.png" alt="" className="img-fluid" />
        </div>

    )

}


export default Imgfblog