import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const NavBlog = () => {

    const [hasScrolled, setHasScrolled] = useState(false);

    useEffect(() => {
        //if(window.innerWidth <= 768) setPadNav(true)

        const handleScroll = () => {
          // Verifica la posición del scroll
          const isScrolled = window.scrollY > 0;
          setHasScrolled(isScrolled);
        };
    
        // Agrega el event listener para el scroll
        window.addEventListener('scroll', handleScroll);
    
        // Limpia el event listener cuando el componente se desmonta
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    return (
        <>
            <nav className={`navbar navbar-expand-lg navbar-light fixed-top bg-pcolor ${hasScrolled ? 'shadow' : ''}`}>
                <div className="container-fluid" style={{ 'zIndex': '99' }} id="navbar">
                    <div className="container">
                        <div className="row p-2">
                            <div className="col-md-6 d-flex align-items-center justify-content-center">
                                <Link to={'/'} className="fw-bold txt-blog px-2 pointer text-light">
                                    <img src="/img/logonav.png" alt="" className="logo pointer" width={'200px'} />
                                </Link>
                            </div>
                            <div className="col-md-6 d-flex align-items-center justify-content-center" id="menu">
                                <Link to={'/'} className="fw-bold txt-blog px-2 pointer text-light">
                                    Inicio
                                </Link>
                                <Link to={'/blog'} className="fw-bold txt-blog px-2 pointer text-light">
                                    Blog
                                </Link>
                                {/*<Link to={'/faqs'} className="fw-bold txt-blog px-2 pointer text-light">
                                    FAQ's
                                </Link>*/}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )

}

export default NavBlog
